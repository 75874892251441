import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = props => (
  <Layout home>
    <SEO title="Home" />
    <h1>Welcome</h1>

    <p>
      Burmpro is a Melbourne-based construction company that prides itself on
      professionalism, workmanship and environmental standards. Learn more about
      how we work.
    </p>
  </Layout>
)

export default IndexPage
